<template>
    <div  class="container mt-5">
      <!-- Modal -->
      <div class="modal show d-block" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ title }}</h5>
            </div>
            <div class="modal-body">
              {{ message }}
            </div>
            <div class="modal-footer">
              <button @click="closeAlertModal" type="button" class="btn btn-secondary" data-dismiss="modal">Ok</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      message: String,
    },
    methods: {
        closeAlertModal() {
            this.$emit("closeAlertModal")
        }
    }
  }
  </script>
  
  <style scoped>
  /* Add custom styles here if needed */
  </style>
  