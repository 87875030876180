<template>
  <div class="container mt-5">
    <!-- Modal -->
    <div class="modal" :class="{ 'show': showModal, 'd-block': showModal }" id="textInputModal" tabindex="-1" aria-labelledby="textInputModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="textInputModalLabel">{{ title }}</h5>
          </div>
          <!-- password input -->
          <div class="modal-body">
            <div class="form-group">
              <label for="password">Nouveau mot de passe:</label>
              <input type="password" class="form-control" v-model="password" id="password" name="password" :minlength="minLength" maxlength="20" placeholder="Enter your password" required>
            </div>
            <div class="form-group">
              <label for="confirmPassword">Confirmez le mot de passe:</label>
              <input type="password" class="form-control" v-model="confirmPassword" id="confirmPassword" name="confirmPassword" :minlength="minLength" maxlength="20" placeholder="Confirm your password" required>
            </div>
            <div v-if="password && confirmPassword && password !== confirmPassword" class="alert alert-danger" role="alert">
              Les mots de passe ne correspondent pas
            </div>
            <div v-if="password && password.length < 8" class="alert alert-danger" role="alert">
              Le mot de passe doit contenir au moins 8 caractères
            </div>
          </div>
            <!-- ------- -->
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal">Annuler</button>
              <button class="btn btn-primary" @click="submitForm" :disabled="password !== confirmPassword || !password || !confirmPassword || password.length < 8">Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ["showModal","nbFields", "title"],
  data() {
    return {
      // for pw input
      password: '',
      confirmPassword: '',
      minLength: 8,
    }
  },
  methods: {
    openModal() {
      // Set showModal to true to show the modal
      // this.showModal = true;
    },
    closeModal() {
      // Set showModal to false to hide the modal
      this.$emit("close-modal")
    },
    submitForm() {
      // Emit event to parent
      this.$emit("submit-input", this.password)
      // Close the modal after submission
      // this.closeModal()
    }
  }
}
</script>

<style>
/* You can add custom styles here */
</style>
