import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Files from '../views/Files.vue'
import ResetPw from '../views/ResetPw.vue'
// import SignUp from '../views/SignUp.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset-pw',
    name: 'ResetPw',
    component: ResetPw
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: SignUp
  // },
  {
    path: '/files',
    name: 'Files',
    component: Files
  },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: Test
  // },
  {
    path: '*',
    redirect: 'login'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router