<template>
  <div>
    <div class="page-content-wrapper">
        <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
            <img src="@/assets/Logos Familles_final-03.png" class="navbar-brand" alt="Ingestic">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto"></ul>
                <span style="margin-right: 15px;" id="connected-as">{{userSession.username}}</span>
                <button class="btn btn-dark btn-nav" @click="logout">Déconnexion</button>
            </div>
        </nav>

        <div class="container" v-if="BUCKET_STRUCTURE">
            <div class="row">
                <div id="theme-buttons" class="col-4 sidenav">
                  <div class="theme-nav" v-for="themeObject of bucket_data" :key="themeObject.theme">
                    <img data-toggle="collapse" :data-target="'#nav-menu-' + themeObject.theme" src="@/assets/chevron_right_logo.svg" alt="toggle"> <img :src="getThemeLogo(themeObject.theme)" alt="">
                    <span @click="detailThemeFolders(themeObject.theme)">{{themeObject.theme}}</span>
                    <div :id="'nav-menu-' + themeObject.theme" class="collapse">
                      <div class="folder-nav" v-for="folder of themeObject.folders.map(folder => folder.name)" :key="folder">
                        <img @click="loadUploadDatesOfFolder(folder, themeObject.theme)" data-toggle="collapse" :data-target="'#dates-nav-' + folder" src="@/assets/chevron_right_logo.svg" alt="toggle">
                        <span @click="detailFolderDates(themeObject.theme, folder)"><strong>{{folder}}</strong></span>
                        <div class="collapse dates-nav" :id="'dates-nav-' + folder">
                          <template v-if="getUploadDatesOfFolder(themeObject.theme, folder) !== null">
                            <div @click="detailFilesAtDate(themeObject.theme, folder, date)" v-for="date of getUploadDatesOfFolder(themeObject.theme, folder)" :key="date">
                              {{formatDate(date)}}
                            </div>
                          </template>
                          <template v-else>
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <h3 v-if="showedDataPath">
                    <span class="selectable" @click="detailThemeFolders(showedDataPath.theme)">{{showedDataPath.theme}}</span>
                    <template v-if="showedDataPath.folder"> &gt; <span class="selectable" @click="detailFolderDates(showedDataPath.theme, showedDataPath.folder)">{{showedDataPath.folder}}</span></template>
                    <span v-if="showedDataPath.date"> > {{formatDate(showedDataPath.date)}}</span>
                  </h3>
                  <template v-if="isFileView">
                    <button data-toggle="modal" data-target="#infosModal" type="button" class="btn btn-light modal-btn">Informations</button>
                    <button @click="deleteFiles(showedDataPath.folder, showedDataPath.date)" type="button" class="btn btn-danger">Supprimer les fichiers</button>
                  </template>
                  <template v-if="isFolderView">
                    <button @click="fileToDetail = null" data-toggle="modal" data-target="#infosModal" type="button" class="btn btn-light modal-btn">Informations</button>
                    <button @click="fileToDetail = null" data-toggle="modal" data-target="#uploadModal" type="button" class="btn btn-primary modal-btn">Ajouter des fichiers</button>
                  </template>
                  <template v-if="showedDataPath.folder">
                    <div>
                      <a :href="currentFolderInfos.url" target="_blank">Lien vers le dataset</a>
                    </div>
                  </template>
                    <template v-if="isFileView">
                      <template v-if="dataToShow.loadedFiles">
                        <div class="alert" :class="dataToShow.status == 'PROCESSED' ? 'alert-success' : 'UNKNOWN' ? 'alert-secondary' : 'alert-danger'" role="alert">
                          Statut : {{dataToShow.status}} -
                          Uploadé par : <strong>{{dataToShow.username ? dataToShow.username : 'inconnu'}}</strong>
                        </div>
                        <ul class="list-group list-group-flush">
                          <div class="list-group-item" v-for="file of dataToShow.files" :key="file">
                            <span class="file-name">{{file}}</span>
                            <span class="download-button-span"><img @click="downloadFile(showedDataPath.folder, showedDataPath.date, file)" class="download-button" src="@/assets/download_logo.svg" alt="Télécharger"></span>
                          </div>
                        </ul>
                      </template>
                      <template v-else>
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="isThemeView || (isFolderView  && displayedBucketData.loadedDates)">
                        <template v-if="dataToShow.length > 0">
                          <div class="card folder-group" style="width: 18rem;">
                              <ul class="list-group list-group-flush">
                                <li @click="isFolderView ? detailFilesAtDate(showedDataPath.theme, showedDataPath.folder, elem) : detailFolderDates(showedDataPath.theme, elem)" v-for="elem of dataToShow" :key="elem" class="list-group-item selectable-folder">
                                  {{isFolderView ?  `Fichiers du ${formatDate(elem)}` : getFolderFullName(showedDataPath.theme, elem)}}
                                </li>
                              </ul>
                          </div>
                        </template>
                        <template v-else>
                          <div v-if="isFolderView" id="no-data-message">
                            <strong>Aucune données dans ce dossier. Cliquez sur "Ajouter des fichiers" pour en ajouter.</strong>
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </template>
                    </template>
                    <!-- upload modal -->
                    <div class="modal" id="uploadModal" tabindex="-1" role="dialog">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Upload {{showedDataPath.folder}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <template v-if="canUploadToday">
                              <div class="modal-body">
                                  <div id="upload-requirements">
                                      Fichiers requis : <br>
                                      <div @click="setFileToDetail(file)" v-for="file of requiredFiles" :key="file.name">
                                        <strong>{{file.name}}</strong> <img src="@/assets/info_logo.svg" alt="Info">
                                      </div>
                                      <div class="fileDetail" v-if="fileToDetail">
                                        <div>Détail du fichier <strong>"{{fileToDetail.name}}"</strong></div>
                                        <div>
                                          <div>
                                            <table class="table">
                                              <thead>
                                                <tr>
                                                  <th scope="col">Nom du champs</th>
                                                  <th scope="col">Description</th>
                                                  <th scope="col">Exemple de valeur</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr v-for="(fieldName, index) in fileToDetail.fields" :key="fieldName">
                                                  <td>{{fieldName}}</td>
                                                  <td>{{fileToDetail.fieldsDescription[index]}}</td>
                                                  <td>{{fileToDetail.fieldsValue[index]}}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                      <div id="file-content-details"></div>
                                  </div><br>
                                  <div id="missing-files">
                                  </div>
                                  <!-- Uploader -->
                                  <div v-show="!uploading && !showUploadConfirm" class="input-group upload-form">
                                      <div class="input-group-prepend">
                                          <button @click="uploadFilesWPresignedURL" type="button" class="btn btn-success" id="uploadButton">Upload</button>
                                      </div>
                                      <div class="custom-file">
                                          <input type="file" class="form-control-file" id="fileinput" aria-describedby="fileinput" @change="addFileName" multiple>
                                          <label class="custom-file-label" for="fileinput" id="fileName">Sélectionner {{requiredFiles.length > 1 ? 'les fichiers' : 'le fichier'}} requis</label>
                                      </div>
                                  </div>
                                  <template v-if="uploading">
                                    <div v-for="fileInUpload of filesInUpload" :key="fileInUpload.name">
                                      {{fileInUpload.name}}
                                      <template v-if="fileInUpload.isUploaded">
                                        <img src="@/assets/check_logo.svg" alt="Uploaded">
                                      </template>
                                      <template v-else>
                                        <span class="spinner-border spinner-border-sm" role="status">
                                          <span class="sr-only">Uploading...</span>
                                        </span>
                                      </template>
                                    </div>
                                  </template>
                                  <div v-if="showUploadConfirm" class="confirm-msg">
                                    Fichiers uploadés
                                  </div>
                              </div>
                            </template>
                            <template v-else>
                              Les uploads sont limités à un par jour par jeu de données.
                            </template>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                      </div>
                    </div>

                    <!-- informations modal -->
                    <div class="modal" id="infosModal" tabindex="-1" role="dialog">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Informations sur : {{getFolderFullName(showedDataPath.theme, showedDataPath.folder)}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div v-if="isFolderView || isFileView" class="modal-body">
                              <div class="dataset-description">
                                {{currentFolderInfos.description_dataset}}
                              </div>
                              <div class="aws-description">
                                {{currentFolderInfos.description_aws}}
                              </div>
                              <div id="upload-requirements">
                                  Fichiers requis : <br>
                                  <div @click="setFileToDetail(file)" v-for="file of requiredFiles" :key="file.name">
                                    <strong>{{file.name}}</strong> <img src="@/assets/info_logo.svg" alt="Info">
                                  </div>
                                  <div class="fileDetail" v-if="fileToDetail">
                                    <div>Détail du fichier <strong>"{{fileToDetail.name}}"</strong></div>
                                    <div>
                                      <table class="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">Nom du champs</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Exemple de valeur</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(fieldName, index) in fileToDetail.fields" :key="fieldName">
                                            <td>{{fieldName}}</td>
                                            <td>{{fileToDetail.fieldsDescription[index]}}</td>
                                            <td>{{fileToDetail.fieldsValue[index]}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div id="file-content-details"></div>
                              </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
//import { BUCKET_STRUCTURE } from '../utils/bucket_structure'
import { jwtService } from '../services'
import moment from 'moment'

export default {
    data() {
        return {
          userSession: null,
          BUCKET_STRUCTURE: null,
          bucket_data: [],
          showedDataPath: {},
          fileToDetail: null,
          uploading: false,
          filesInUpload: [],
          showUploadConfirm: false,
          canUploadToday: true
        }
    },
    computed: {
      bucket_themes() {
        if(!this.BUCKET_STRUCTURE)
          return []
        return this.BUCKET_STRUCTURE.map(theme => theme.name)
      },
      dataToShow() {
        if(this.isFileView) {
          return this.getThemeData(this.showedDataPath.theme).folders.find(folder => folder.name == this.showedDataPath.folder).datesAndFiles.find(dateAndFiles => dateAndFiles.date == this.showedDataPath.date)
        }
        if(this.showedDataPath.folder) {
          let folderDatesAndFiles = this.getThemeData(this.showedDataPath.theme).folders.find(folder => folder.name == this.showedDataPath.folder).datesAndFiles
          if(!folderDatesAndFiles)
            return null
          return folderDatesAndFiles.map(datesAndFiles => datesAndFiles.date)
        }
        if(this.showedDataPath.theme) {
          return this.getThemeData(this.showedDataPath.theme).folders.map(folder => folder.name)
        }
        return null
      },
      isFileView() {
        return this.showedDataPath.date
      },
      isFolderView() {
        return this.showedDataPath.folder && !this.showedDataPath.date
      },
      isThemeView() {
        return !this.isFolderView && !this.isFileView
      },
      requiredFiles() {
        if(!(this.isFileView || this.isFolderView))
          return []
        return this.BUCKET_STRUCTURE.find(themeData => themeData.name == this.showedDataPath.theme)
          .folders.find(bucket_folder => bucket_folder.name == this.showedDataPath.folder)
          .files
      },
      currentFolderInfos() {
        if(!this.showedDataPath.folder)
          return null
        return this.BUCKET_STRUCTURE.find(themeData => themeData.name == this.showedDataPath.theme)
          .folders.find(bucket_folder => bucket_folder.name == this.showedDataPath.folder)
      },
      displayedBucketData() {
        if(this.isThemeView) {
          return this.bucket_data.find(themeData => themeData.theme == this.showedDataPath.theme)
        }
        if(this.isFolderView) {
          return this.bucket_data.find(themeData => themeData.theme == this.showedDataPath.theme).folders.find(folder => folder.name == this.showedDataPath.folder)
        }
        return this.bucket_data.find(themeData => themeData.theme == this.showedDataPath.theme).folders.find(folder => folder.name == this.showedDataPath.folder).datesAndFiles
      }
    },
    methods: {
      uploadFilesWPresignedURL() {
        this.uploading = false
        this.filesInUpload = []
        this.checkTokenExpiration()
        document.getElementById('missing-files').innerHTML = ''
        const requiredFiles = this.requiredFiles.map(file => file.name)
        let files = Array.from(document.getElementById('fileinput').files)

        if (!files.length) {
            //showModal("Warning", "You need to choose a file to upload.")
            return false
        }

        // check that the required files are all here
        let missingFiles = ''
        requiredFiles.forEach(requiredFile => {
            if(!files.find(file => file.name == requiredFile)) {
                missingFiles += requiredFile + '<br>'
            }
        })
        if(missingFiles.length > 0) {
            // todo tell the user which files are missing
            document.getElementById('missing-files').innerHTML = '<span class="important">Fichiers manquants :</span><br>' + missingFiles
            return
        }
        this.uploading = true
        this.filesInUpload = files.map(fileInUpload => {return {name: fileInUpload.name, isUploaded: false}})
        let uploadStatus = []
        this.readFilesContentAndUpload(files, uploadStatus, this.showedDataPath.folder, moment().format('YYYYMMDD'))
      },
      readFilesContentAndUpload(files, uploadStatus, folder, date) {
        let reader = new FileReader()
        reader.addEventListener('load', () => {
            //filesNamesAndContent.push({name: file.name, content: reader.result})
            this.getUploadPresignedURL(folder, date, files[0].name, files, uploadStatus, reader.result)
        })
        reader.readAsText(files[0])
      },
      getUploadPresignedURL(folder, date, fileName, files, uploadStatus, fileContent) {
        fetch(`https://rk09xn06f6.execute-api.eu-west-1.amazonaws.com/prod/presigned_url?isForUpload=true&folder=${folder}&date=${date}&fileName=${fileName}`, {
        method: 'GET',
        headers: {
                'Authorization': 'Bearer ' + this.userSession.jwt
            },
        })
        .then((result) => {
              return result.json()
          }).then(data => {
              this.uploadFileWPresignedURL(data, files, fileContent, uploadStatus, folder, date)
          })
      },
      uploadFileWPresignedURL(presignedURL, files, fileContent, uploadStatus, folder, date) {
        let formData = new FormData()
        for(const [key, value] of Object.entries(presignedURL.url.fields)) {
          formData.append(key, value)
        }
        formData.append('file', fileContent)
        fetch(presignedURL.url.url, {
          method: 'POST',
          body: formData
        }).then(_ => {
          this.setFileMetadata(folder, date, files, uploadStatus)
        })
        .catch(_ => {
          this.uploading = false
          this.filesInUpload = []
          // todo cancel and delete uploaded uploaded files (and the new folder)
        })
      },
      setFileMetadata(folder, date, files, uploadStatus) {
        fetch('https://rk09xn06f6.execute-api.eu-west-1.amazonaws.com/prod/set_metadata', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.userSession.jwt
            },
            body: JSON.stringify({
                "folder": folder,
                "date": date,
                "username": this.userSession.username,
                "fileName": files[0].name
            })
        }).then(() => {
          this.filesInUpload.find(fileInUpload => fileInUpload.name == files[0].name).isUploaded = true
          files.shift()
          if(files.length > 0) {
            this.readFilesContentAndUpload(files, uploadStatus, folder, date)
          }
          else {
            this.uploading = false
            this.filesInUpload = []
            this.showUploadConfirm = true
            setTimeout(() => {
              this.triggerUploadedFilesProcessing(folder, date)
              this.showUploadConfirm = false
              let bucket_data_folder = this.getThemeData(this.showedDataPath.theme).folders.find(folderData => folderData.name == folder)
              bucket_data_folder.datesAndFiles = null
              bucket_data_folder.loadedDates = false
              this.detailFolderDates(this.showedDataPath.theme, this.showedDataPath.folder)
              $('#uploadModal').modal('hide')
            }, 1500)
          }
        })
        .catch(_ => {
          this.uploading = false
          this.filesInUpload = []
        })
      },
      triggerUploadedFilesProcessing(folder, date) {
        let bucket_data_folder = this.getThemeData(this.showedDataPath.theme).folders.find(folderData => folderData.name == folder)

        fetch('https://rk09xn06f6.execute-api.eu-west-1.amazonaws.com/prod/data_processing', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.userSession.jwt
            },
            body: JSON.stringify({
                scriptName: bucket_data_folder.aws_lambda,
                folder: folder,
                date: date
            })
        }).then(() => {})
        .catch(_ => {})
      },
      loadBucketStructure() {
        this.checkTokenExpiration()
        fetch(`https://rk09xn06f6.execute-api.eu-west-1.amazonaws.com/prod/bucket_structure`, {
        method: 'GET',
        headers: {
                'Authorization': 'Bearer ' + this.userSession.jwt
            },
        })
        .then((result) => {
                return result.json()
            }).then(data => {
                this.BUCKET_STRUCTURE = data
                this.initBucketData()
                this.detailThemeFolders(this.BUCKET_STRUCTURE[0].name)
            })
      },
      getFolderFullName(theme, folderName) {
        let themeFound = this.BUCKET_STRUCTURE.find(themeData => themeData.name == theme)
        if(!themeFound)
          return ''
        let foundFolder = themeFound.folders.find(folderData => folderData.name == folderName)
        if(!foundFolder)
          return ''
        return foundFolder.fullName
      },
      addFileName() {
          let files = Array.from(document.getElementById('fileinput').files)
          document.getElementById('fileName').innerHTML = files.map(file => file.name).join(', ')
      },
      setFileToDetail(file) {
        this.fileToDetail = this.requiredFiles.find(rFile => rFile.name == file.name)
      },
      deleteFiles(folder, date) {
        this.checkTokenExpiration()
        if(!confirm(`Supprimer les fichiers du dossier ${folder} à la date du ${this.formatDate(date)}?`))
          return
        fetch('https://rk09xn06f6.execute-api.eu-west-1.amazonaws.com/prod/delete_files', {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + this.userSession.jwt
            },
            body: JSON.stringify({
                "folder": folder,
                "date": date
            })
        }).then(() => {
            let bucket_data_folder = this.getThemeData(this.showedDataPath.theme).folders.find(folderData => folderData.name == folder)
            bucket_data_folder.datesAndFiles = null
            bucket_data_folder.loadedDates = false
            this.detailFolderDates(this.showedDataPath.theme, this.showedDataPath.folder)
            $('#uploadModal').modal('hide')
        }).catch(_ => {
          // todo show error message for delete
        })
      },
      downloadFile(folder, date, fileName) {
        this.checkTokenExpiration()
        fetch(`https://rk09xn06f6.execute-api.eu-west-1.amazonaws.com/prod/presigned_url?isForUpload=false&folder=${folder}&date=${date}&fileName=${fileName}`, {
        method: 'GET',
        headers: {
                'Authorization': 'Bearer ' + this.userSession.jwt
            },
        })
        .then((result) => {
                return result.json()
            }).then(data => {
                window.open(data.url, '_blank').focus()
            })
      },
      detailFilesAtDate(theme, folder, date) {
        this.fileToDetail = null
        // check if files are already loaded
        this.loadFilesOfFolderAtDate(theme, folder, date)
        this.showedDataPath = {
          theme: theme,
          folder: folder,
          date: date
        }
      },
      detailThemeFolders(theme) {
        this.showedDataPath = {
          theme: theme
        }
      },
      detailFolderDates(theme, folder) {
        // since the theme could be
        this.loadUploadDatesOfFolder(folder, theme)
        this.showedDataPath = {
          theme: theme,
          folder: folder
        }
      },
      logout() {
        jwtService.logOut()
      },
      checkLogin() {
        if(!this.userSession || !this.userSession.jwt)
          window.location.href = '/login'
        const idToken = this.userSession.idToken
        if(!idToken)
          window.location.href = '/login'
        const userGroups = idToken.payload["cognito:groups"]
        if(!userGroups || !userGroups.includes("la-louviere-data-provider")) {
          window.location.href = '/login'
        }
        this.checkTokenExpiration()
      },
      getThemeLogo(theme) {
        return require('@/assets/' + this.BUCKET_STRUCTURE.find(themeData => themeData.name == theme).logo)
      },
      loadUploadDatesOfFolder(folder, theme) {
        let themeData = this.getThemeData(theme)
        let bucket_data_folder = themeData.folders.find(theme_folder => theme_folder.name == folder)
        if(bucket_data_folder.loadedDates) {
          return
        }
        this.checkTokenExpiration()
        let url = 'https://rk09xn06f6.execute-api.eu-west-1.amazonaws.com/prod/list_files'
        fetch(`${url}?datesOnly=True&folders=${folder}`, {
            method: 'GET',
            headers: {
                    'Authorization': 'Bearer ' + this.userSession.jwt
                }
            })
            .then(function (response) {
                if (!response.ok) {
                    return
                }
                return response.json()
            }).then(data => {
                bucket_data_folder.datesAndFiles = []
                bucket_data_folder.loadedDates = true
                let loadedDates = data.foldersAndDates[0].dates
                loadedDates.forEach(date => {
                  bucket_data_folder.datesAndFiles.push({
                    date: date,
                    files: null,
                    status: 'UNKNOWN',
                    username: null,
                    loadedFiles: false
                  })
                })
            })
      },
      getUploadDatesOfFolder(theme, folder) {
        let datesAndFiles = this.getThemeData(theme).folders.find(theme_folder => theme_folder.name == folder).datesAndFiles
        if(datesAndFiles == null)
          return null
        return datesAndFiles.map(dateAndFiles => dateAndFiles.date)
      },
      getThemeData(theme) {
        return this.bucket_data.find(theme_data => {
          return theme_data.theme == theme
        })
      },
      getFoldersOfTheme(theme) {
        return this.BUCKET_STRUCTURE.find(theme_structure => {
          return theme_structure.name == theme
        }).folders.map(folder => folder.name)
      },
      getFullNameFoldersOfTheme(theme) {
        return this.BUCKET_STRUCTURE.find(theme_structure => {
          return theme_structure.name == theme
        }).folders.map(folder => folder.fullName)
      },
      loadFilesOfFolderAtDate(theme, folder, date) {
        let themeData = this.getThemeData(theme)
        let bucket_data_folder_files = themeData.folders.find(theme_folder => theme_folder.name == folder).datesAndFiles.find(dateAndFiles => dateAndFiles.date == date)
        if(bucket_data_folder_files.loadedFiles) {
          return
        }
        this.checkTokenExpiration()
        let url = 'https://rk09xn06f6.execute-api.eu-west-1.amazonaws.com/prod/list_files'
        fetch(`${url}?datesOnly=False&folders=${folder}&date=${date}`, {
            method: 'GET',
            headers: {
                    'Authorization': 'Bearer ' + this.userSession.jwt
                }
            })
            .then(function (response) {
                if (!response.ok) {
                    return
                }
                return response.json()
            }).then(data => {
              bucket_data_folder_files.loadedFiles = true
              bucket_data_folder_files.files = data.files
              bucket_data_folder_files.username = data.username
              bucket_data_folder_files.status = data.status
            })
      },
      getFilesOfFolderAtDate(theme, folder, date) {
        let themeData = this.getThemeData(theme)
        let bucket_data_folder_files = themeData.folders.find(theme_folder => theme_folder.name == folder).datesAndFiles.find(dateAndFiles => dateAndFiles.date == date)
        return bucket_data_folder_files.files
      },
      formatDate(d) {
        let date = new Date(`${d.substring(0,4)}-${d.substring(4,6)}-${d.substring(6)}`)
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
          month = month.toString()
          day = day.toString()
        if(month.length < 2)
          month = '0' + month
        if(day.length < 2)
          day = '0' + day
        return `${day}-${month}-${year}`
      },
      checkTokenExpiration() {
        jwtService.checkTokenExpiration()
      },
      getAWSLambdaOfFolder(theme, folder) {
        return this.BUCKET_STRUCTURE.find(themeData => themeData.name == theme)
          .folders.find(themeFolder => themeFolder.name == folder).aws_lambda
      },
      initBucketData() {
        this.bucket_themes.forEach(theme => {
          let foldersData = []
          this.getFoldersOfTheme(theme).forEach(folder => {
            let aws_lambda = this.getAWSLambdaOfFolder(theme, folder)
            foldersData.push({
              name: folder,
              aws_lambda: aws_lambda,
              datesAndFiles: null,
              loadedDates: false
            })
          })
          this.bucket_data.push({
            theme: theme,
            folders: foldersData
          })
        })
      }
    },
    created() {
      this.userSession = JSON.parse(localStorage.getItem('session'))
      this.checkLogin()
      this.loadBucketStructure()
    }
}
</script>

<style scoped>
.dataset-description, .aws-description {
  margin-bottom: 12px;
}
.navbar-brand {
  max-height: 60px;
}
.container {
  margin-left: 0%;
  margin-right: 0%;
}
.folder-nav {
  margin-left: 18px;
}
.theme-nav {
  width: 100%;
  text-align: left;
}
.dates-nav, .folder-nav, .theme-nav {
  margin-left: 30px;
  cursor: pointer;
}
.sidenav {
  font-size: 20px;
}
.modal-btn {
  margin: 8px;
}
/* .file-name {
  text-align: left;
} */
.download-button-span {
  float: right;
}
.fileDetail {
  margin-top: 14px;
}
.important {
  color: red;
}
.confirm-msg {
  color: green;
}
.folder-group {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}
.selectable, .selectable-folder, .download-button {
  cursor: pointer;
}
.theme-nav [aria-expanded="false"] {
  transform: rotate(0deg)
}
.theme-nav [aria-expanded="true"] {
  transform: rotate(90deg)
}
#no-data-message {
  margin-top: 12px;
  font-size: 20px;
  text-align: start;
}
</style>