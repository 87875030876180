<template>
    <div class="container">
      <AlertModal
        v-if="showAlertModal"
        @closeAlertModal="closeAlertModal"
        :title="alertTitle"
        :message="alertMessage">
      </AlertModal>
        <div class="row">
            <div class="col-md-5 offset-md-4">
                <h1 class="text-center signin-title">Mot de passe oublié</h1>
                <form onsubmit="return false;">
                    <div class="form-group input-field">
                        <label for="username">Email*</label>
                        <input v-model="username" type="email" class="form-control rounded-0" name="username" id="username" aria-describedby="emailHelp" placeholder="Adresse email">
                    </div>
                    <!-- verif code input -->
                    <VerifCodeInput
                      @code-change="verifCodeChange">
                    </VerifCodeInput>
                    <div class="mt-2">
                      <label>Ou</label>
                      <div class="d-flex justify-content-center mt-1">
                        <button id="request-code-btn" @click="requestVerifCode" type="button" class="btn btn-primary btn-md px-4">
                          <span v-if="sendingCode" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                          Recevoir un nouveau code
                        </button>
                      </div>
                    </div>
                    <!-- new password zone -->
                    <div class="mt-5" v-if="verifCode">
                      <div class="form-group">
                        <label for="password">Nouveau mot de passe*</label>
                        <input type="password" class="form-control" v-model="password" id="password" name="password" minlength="minLength" maxlength="20" placeholder="Entrez votre nouveau mot de passe" required>
                      </div>
                      <div class="form-group">
                        <label for="confirmPassword">Confirmez le mot de passe*</label>
                        <input type="password" class="form-control" v-model="confirmPassword" id="confirmPassword" name="confirmPassword" minlength="minLength" maxlength="20" placeholder="Confirmez votre nouveau mot de passe" required>
                      </div>
                      <div v-if="password && confirmPassword && password !== confirmPassword" class="alert alert-danger" role="alert">
                        Les mots de passe ne correspondent pas
                      </div>
                      <div v-if="password && password.length < 8" class="alert alert-danger" role="alert">
                        Le mot de passe doit contenir au moins 8 caractères
                      </div>

                      <!-- <button class="btn btn-success" @click="resetUserPassword"></button> -->
                      <button class="btn btn-primary" @click="resetUserPassword" :disabled="password !== confirmPassword || !password || !confirmPassword || password.length < 8">Soumettre mon nouveau mot de passe</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
  <script>
  import * as  AmazonCognitoIdentity from "amazon-cognito-identity-js"
  import AlertModal from "./components/AlertModal.vue"
  import VerifCodeInput from "./components/VerifCodeInput.vue"
  
  export default {
    components: {
      AlertModal,
      VerifCodeInput
    },
    data() {
        return {
            username: null,
            password: '',
            confirmPassword: '',
            verifCode: '',
            sendingCode: false,
            userPoolId: 'eu-west-1_xlCDflQs4',
            clientId: '4q26d5v4j468b0qh61qnp062u9',
            poolData: null,
            cognitoUser: null,
            userPool: null,
            // alert modal
            showAlertModal: false,
            alertTitle: "",
            alertMessage: "",
        }
    },
    methods: {
      closeAlertModal() {
        this.showAlertModal = false
      },
      displayAlertModal(title, message) {
            this.alertTitle = title
            this.alertMessage = message
            this.showAlertModal = true
        },
      verifCodeChange(verifCode) {
        this.verifCode = verifCode
      },
      requestVerifCode() {
        if(this.sendingCode || !this.username)
          return

          this.cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: this.username,
            Pool: this.userPool
          })
          this.sendingCode = true
          // request a verif code
          this.cognitoUser.forgotPassword({
              onSuccess: (_) => {
                  this.sendingCode = false
              },
              onFailure: (_) => {
                this.sendingCode = false
                this.displayAlertModal("Envoi du code de vérification", "Echec de l'envoi du code de vérification, veuillez vérifier votre adresse email")
              },
              inputVerificationCode: (_) => {
                this.sendingCode = false
                this.displayAlertModal("Envoi du code de vérification", "Code envoyé avec succès")
              }
          })
      },
      resetUserPassword() {
          this.cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: this.username,
            Pool: this.userPool
          })
          // use verif code to set new pw
          this.cognitoUser.confirmPassword(this.verifCode, this.password, {
              onSuccess: () => {
                this.displayAlertModal("Nouveau mot de passe", "Nouveau mot de passe défini avec succès")
                setTimeout(() => {
                  this.$router.push('/login')
                }, 5000)
              },
              onFailure: (e) => {
                if(e && e.code == "CodeMismatchException")
                  this.displayAlertModal("Nouveau mot de passe", "Code de vérification incorrect")
                else {
                  if(e && e.code == "LimitExceededException") {
                    this.displayAlertModal("Nouveau mot de passe", "Nombre maximum de tentatives atteint, veuillez réessayer plus tard")
                  }
                  else {
                    this.displayAlertModal("Nouveau mot de passe", "Echec lors de la définition du nouveau mot de passe. Si l'erreur persiste, contactez un administateur de la plateforme")
                  }
                }
              }
          })
      }
    },
    created() {
        this.poolData = {
            UserPoolId : this.userPoolId,
            ClientId : this.clientId
        }
        this.userPool = new AmazonCognitoIdentity.CognitoUserPool(this.poolData)
    }
  }
  </script>
  
  
  <style >
  .signin-title {
      margin: 15px;
  }

  #request-code-btn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  label {
    font-weight: 600;
    font-size: large;
  }
  </style>