<template>
  <div>
    <div class="container">
        <div class="row">
            <div class="col-md-4 offset-md-4">
                <h1 class="text-center signin-title">Connexion</h1>
                <form onsubmit="return false;">
                    <div class="form-group input-field">
                        <label for="username">Email</label>
                        <input v-model="username" type="text" autocomplete="email" class="form-control rounded-0" name="username" id="username" aria-describedby="emailHelp" placeholder="Email">
                    </div>
                    <div class="form-group input-field">
                        <label for="password">Mot de passe</label>
                        <input v-model="password" autocomplete="current-password" type="password" class="form-control rounded-0" name="password" id="password" placeholder="Mot de passe">
                    </div>
                    <button class="btn btn-custom signin" @click="login">Se connecter</button>
                    <button class="btn" @click="goToForgotPW">Mot de passe oublié</button>
                </form>
            </div>
        </div>
    </div>
    <InitialPw
        @submit-input="changePw"
        @close-modal="closePwModal"
        :showModal="showPwModal"
        :title="'Veuillez définir un nouveau mot de passe'"
    ></InitialPw>
    <AlertModal
        v-if="showAlertModal"
        @closeAlertModal="closeAlertModal"
        :title="alertTitle"
        :message="alertMessage">
    </AlertModal>
  </div>
</template>

<script>
import * as  AmazonCognitoIdentity from "amazon-cognito-identity-js"
import InitialPw from "./components/InitialPw.vue"
import AlertModal from "./components/AlertModal.vue"

export default {
    components: {
    InitialPw,
    AlertModal
},
    data() {
        return {
            username: null,
            password: null,
            userPoolId: 'eu-west-1_xlCDflQs4',
            clientId: '4q26d5v4j468b0qh61qnp062u9',
            poolData: null,
            cognitoUser: null,
            userPool: null,
            showPwModal: false,
            // alert modal
            showAlertModal: false,
            alertTitle: "",
            alertMessage: "",
        }
    },
    methods: {
        displayAlertModal(title, message) {
            this.alertTitle = title
            this.alertMessage = message
            this.showAlertModal = true
        },
        closeAlertModal() {
            this.showAlertModal = false
        },
        login() {
            let authenticationData = {
                Username: this.username,
                Password: this.password
            }

            let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)

            let userData = {
                Username : this.username,
                Pool : this.userPool
            }
            this.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

            this.cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session) => {
                    this.onLoginSuccess(session)
                },

                newPasswordRequired: () => {
                    this.openPwModal()
                },

                onFailure: (_) => {
                    this.displayAlertModal("Connexion", "Echec de la connexion.\nVérifiez vos identifiants")
                }
            })
        },
        onLoginSuccess(session) {
            // verify that user has been added to required group
            const idToken = session.getIdToken()
            const userGroups = idToken.payload["cognito:groups"]
            if(!userGroups || !userGroups.includes("la-louviere-data-provider")) {
                // inform user that he needs to be granted access to the platform
                this.displayAlertModal("Accès refusé", "Vous ne disposez pas du rôle nécessaire pour accéder à la plateforme.\nContactez un administrateur pour vous faire assigner le rôle nécessaire.")
                return
            }

            // add user session in local storage
            localStorage.setItem('session', JSON.stringify({
                idToken: session.getIdToken(),
                jwt: this.cognitoUser.signInUserSession.idToken.jwtToken,
                username: this.cognitoUser.username,
                expiration: this.cognitoUser.signInUserSession.idToken.payload.exp * 1000
            }))

            // navigate to content page
            this.$router.push('/files')
        },
        openPwModal() {
            this.showPwModal = true
        },
        closePwModal() {
            this.showPwModal = false
        },
        changePw(newPassword) {
            if(!newPassword || !newPassword.length >= 8)
                return
            this.cognitoUser.completeNewPasswordChallenge(newPassword, null, {
                onSuccess: (session) => {
                    this.displayAlertModal("Définition d'un mot de passe", "Mot de passe défini avec succès")
                    this.onLoginSuccess(session, this.cognitoUser)
                    this.showPwModal = false
                },
                onFailure: (_) => {
                    this.displayAlertModal("Définition d'un mot de passe", "Erreur lors de la modification du mot de passe")
                }
            })
        },
        goToForgotPW() {
            this.$router.push('/reset-pw')
        }
    },
    created() {
        this.poolData = {
            UserPoolId : this.userPoolId,
            ClientId : this.clientId
        }
        this.userPool = new AmazonCognitoIdentity.CognitoUserPool(this.poolData)
    }
}
</script>


<style scoped>
.signin-title {
    margin: 15px;
}
.input-field>label:first-of-type{
    display:block;
    margin-bottom:.5rem;
    font-weight:400
}
.input-field>label:first-of-type.h5{
    font-weight:600
}

.input-field{
    position:relative;
    margin-bottom:1.5rem;
    margin-top:1.5rem;  
    border-radius:0px;
}
.btn-custom{
    background-color:#009933;
    border-color: #00802b;
    color: white;
}

.btn-custom:active,.btn-custom:target,
.btn.btn-custom:active,.btn.btn-custom:focus,.btn.btn-custom:hover 
{
    -webkit-box-shadow:0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    box-shadow:0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);

    background-color: white;
    color: #009933;
    border-color: #009933;
}


.btn.btn-dark:active,.btn.btn-dark:focus,.btn.btn-dark:hover {
    -webkit-box-shadow:0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    box-shadow:0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: white;
    color: black;
}
</style>