export const jwtService = {
    checkTokenExpiration,
    logOut
}

function checkTokenExpiration() {
    let session = JSON.parse(localStorage.getItem('session'))
    if(!session) {
        // redirect user to login page
        window.location.href = 'login'
    }
    if(new Date() > new Date(session.expiration)) {
        localStorage.removeItem('session')
        window.location.href = 'login'
    }
}

function logOut() {
    localStorage.removeItem('session')
    window.location.href = 'login'
}