<template>
  <div class="container">
    <label for="verification-code" class="mb-2">Code de vérification*</label>
    <div class="d-flex justify-content-center">
      <VueVerificationCodeInput
        :type="'number'"
        :fields="6"
        v-on:change="handleInput"
        inputStyle="text-align: center;"
      />
    </div>
  </div>
</template>

<script>
import VueVerificationCodeInput from 'vue-verification-code-input';

export default {
  components: {
    VueVerificationCodeInput
  },
  methods: {
    handleInput(code) {
      this.$emit('code-change', code);
    }
  }
}
</script>

<style>
  /* Add custom styles here if needed */
</style>
